
@import '~antd/dist/antd.css';


:root {
  --color-primary: #2596be;
  --color-white: #ffffff;
  --color-light: #f3f3f3;
  --color-gray: #000000;

  --antd-wave-shadow-color: #2596be !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #2596be !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #2596be !important;
}

.ant-tabs-ink-bar {
  height: 5px;
  background: #2696BE !important;
}

img[src=""],
img:not([src]){
  opacity: 0;
}

img[src="*"]{
  opacity: 1;
}

button {
  transition: all 0.3s ease-out;
}

button:hover {
  opacity: 0.7;
}


html, body {
  width: 100%;
  height: 100%;
}


body {
  margin: 0;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.footer-copyright {
  color: #000000;
  margin-left: 1em;
  margin-bottom: 1em;
}

.footerlinks {
  margin-left: auto;
  font-size: 0.9em;
  max-width: 30vw;
  height: 100%;
}

.appbar {
  display: flex;
  flex-direction: horizontal;
  align-items: center;
  width: 100%;
  padding: 1.5em;
  background: rgb(255, 255, 255);
  position: fixed;
  z-index: 5;
}

.appbar-user {
  display: flex;
  flex-direction: horizontal;
  align-items: center;
  width: 100%;
  height: 13vh;
  position: fixed;
  z-index: 5;
}

.user-main--content {
  position: relative;
  height: calc(100vh - 13vh);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  max-width: 100vw;
  width: 100vw;
  margin-top: 13vh;
}

.home-c--content {
  border-top-left-radius: 6em;
  border: 1px solid rgb(228, 228, 228);
  background-color: #ffffff;
  height: calc(100vh - 13vh);

}

.home-c {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  width: 100%;
}

.home-c h1 {
  margin-top: 2em;
  margin-bottom: 1.3em;
  font-weight: 300;
}

.appbar a,  .footer a{
  text-decoration: none;

}

.appbar--logo:hover {
  opacity: 0.7;
}

.footer .appbar--logo {
  filter: none;

}

.menu-item {
  padding-left: 1rem;
  padding-right: 2rem;
  font-weight: 500;
  flex-grow: 1;
  width: 100%;
}

.form--addon {
  margin-left: auto;
  color: rgb(168, 168, 168);
  border: none;
  font-size: 0.95em;
  transition: all 0.3s ease-out;
  background-color: transparent;
  cursor: pointer;
}

.delete-form {
  margin-left: auto;
  margin-top: 5em;
  margin-bottom: -1.5em;
  font-size: 1em;
}

.user-appbar--logo {
  color:  var(--color-primary);
  margin-right: auto;
  padding-left: 1em;
  font-size: 2em;
  font-weight: 200;
  font-family: 'Poppins', sans-serif;
}

.appbar--logo {
  color:  var(--color-primary);
  margin-right: auto;
  padding: 0em 5%;
  font-size: 2em;
  font-weight: 200;
  font-family: 'Poppins', sans-serif;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  justify-content: center !important;
}

.appbar--logo img, .user-appbar--logo img {
  height: 60px;
}

.appbar--logo span {
  color:  #000000;
  font-size: 1em;
}

.appbar--links, .userappbar--links {
  display: flex;
  flex-direction: horizontal;
  padding-right: 1em;
  font-size: 1em;
  font-weight: 500;
}

.appbar--links-link {
  margin: 0 1.3em;
  transition: all 0.3s ease-out;
  color: #36495e;
  text-align: center;
  height: 40px;
  line-height: 40px;
}

.appbar--links-link:hover {
  opacity: 0.5;
}

.login-button {
  border: 1px solid #464646;
  width: 100px;
  border-radius: 1.5em;
  margin-right: 4rem;
}

.user-home--link {
  color: black;
}

.user-home--link {
  color: rgb(71, 71, 71);
  font-size: 0.9em;
}

.pcr-button {
  border: 1px solid #2596be;
  font-size: 0.85em;
  line-height: 1.3em;
  width: 140px;
  font-weight: 600;
  padding: 1em 0;
  color: white;
  border-radius: 2em;
  background-color: #2596be;
  margin-right: 2em;
}
.pcr-button span {
  font-size: 1.2em;
  line-height: 1em;
  margin-right: 0.2em;
}
.pcr-button:hover {
  color: white;
}

.searchbar {
  flex-grow: 1;
}
.searchbar input {
  width: 100%;
  padding: 0.4em;
  border-radius: 2em;
  border: none;
  background-color: #F7FAFB;
  border: 1px solid rgb(219, 219, 219);
}

.footer {
  display: flex;
  flex-direction: horizontal;
  align-items: center;
  padding: 2em 10%;
  background-color: white;
  color: rgb(70, 70, 70);
  overflow-x: none;
  margin-top: auto;
  border-top: 1px solid rgb(224, 224, 224);
}


.main-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  max-width: 100vw;
  max-height: 100vh;
  overflow-y: scroll;
}

.main-content {
  flex-grow: 1;
}

.appbar, .main-content, .footer {
  flex-shrink: 0;
}

.v-flex {
  display: flex;
  flex-direction: column;
}

.h-flex {
  display: flex;
  flex-direction: horizontal;
}

.footerlinks .appbar--links-link {
  text-align: left;
  color: #7a7a7a;
  margin: 0 0.5em;
  padding: 0.2em 0.3em;
}

.footerHeader {
  margin-top: -5em;
}

.header-text {
  width: 50vw;
  margin-right: 30vw;
}

.site-item {
  padding: 1em 1%;
}

.site-item h3 {
  font-size: 1.3em;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 17.5px;
  text-transform: uppercase;
  color: #191B1D;
}

.site-item p {
  font-size: 1.1em;
}

.site-section {
  display: flex;
  padding: 5em 8.5%;
  max-width: 100vw;
}

.landing-first {
  width: 100vw;
  justify-content: center;
  text-align: center;
  padding: 1em 0;
}

.light-red {
  background-color: white;
}

.light-grey {
  background-color: #f5f3f3c0;
}

.white-grey {
  background-color: white;
}

.white-grey-outline {
  background-color: white;

}

.header-control {
  position: relative;
  min-height: 90vh;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgb(241, 241, 241);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;  
  background: rgb(255, 255, 255);
  border-bottom-left-radius: 9em;
}

.pulse-img {
  position: absolute;
  right: 0;
}

.header-control h1 {
  margin-top: 2em;
  font-size: 3.5em;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 51px;
  text-transform: none;
  color:#000000;
  width: 80%;
}

.header-control span {
  color:#2596be;
;

}
.site-section h2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 2px;
  color: #191B1D;
  font-size: 2em;
}


.about-svg {
  width: 450px;
  object-fit:contain;
}


.header-control p {
  margin-top: -0.5em;
  margin-bottom: 2.5em;
  font-weight: 400;
  font-size: 1.15em;
  width: 70%;
  color: #999999;
}

.header-sec {
  padding-top: 8em;
}

.header-control img {
  position: absolute;
  object-fit: fill;
  width: 50%;
  margin-top: 80px;
  right: -95px;
  max-height: 450px;
}

.header-control button{
  cursor: pointer;
  width: 150px;
  height: 40px;
  border: none;
  background-color: var(--color-primary);
  color: white;
  font-weight: 500;
  font-size: 1em;
  border-radius: 1.5em;
}


.grid-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.home-middle {
  max-width: 100vw;
}

.home-grid {
  margin-left: 10vw;
}





.grid-item {
  position: relative;
  margin: 0 3%;
  width: 300px;
  justify-content: start;
}
.grid-security-item {
  margin: 1% 2% !important;
  font-size: 1.2em;
  width: 500px !important;
}

.grid-item .img-content {
  font-size: 2em;
  color: var(--color-primary);
  margin-right: 7%;
}


.grid-landing-message .img-content{
  font-size: 2.4em;
  margin-bottom: 0.4em;
}

.grid-landing-message .mold {
  display: flex;
  justify-content: center;
  width: 100%;
}

.grid-landing-message span {
  background-color: rgba(104, 104, 104, 0.1);
  padding: 0.8em;
  border-radius: 50%;
}


.grid-landing-message h3 {
  font-size: 1.5em;
  font-weight: 500;
}

.grid-landing-message p {
  font-size: 1.1em !important;
  color: #696969;
}


.about-why h3 {
  font-size: 1.3em !important;
  font-weight: 500 !important;
}

.about-why p {
  font-size: 0.85em !important;
}

.grid-item p {
  margin-top: 0em;
  font-size: 1.2em;
}

.grid-mod {
  display: flex;
}

.header-p {
  margin-top: -0.5em;
  width: 70%;
}

img.teams-pic {
  background-color: rgb(245, 244, 244);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 1em 3em;
  object-fit: cover;
  opacity: 1 !important;
}

.iteml {
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;

}

.team-item {
  align-items: center;
  justify-content: center;
}

.team-item p {
  font-size: 0.95em;
  width: 100%;
}


.home-about {
  text-align: center;
  flex-direction: column;
}
.home-about h2 {
  margin-bottom: 1em;
}

.home-why {
  flex-direction: column;
}
.home-why h2 {
  text-align: center;
  margin-bottom: 2em;
  margin-top: 1em;

}

.teams-name {
  text-align: center;
}

.dash-main-container {
  display: flex;
  overflow: hidden;
}



.dash-main--content {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  max-width: 100vw;
  width: calc(100vw - 300px);
  background-color: white;
}


.sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgb(211, 211, 211);
  padding: 1em 2%;
  width: 300px;
  height: 100vh;
  flex-grow: 1;
  justify-content: center;
  background-color: #0E0D24;

}

.sidebar .appbar--logo {
  position: absolute;
  top: 2rem;
  left: 0;
  text-decoration: none;
  color: white;
}

.sidebar .sidebar--links {
  display: flex;
  padding: 1.3em 10%;
  margin: 0.8em 0;
  border-radius: 3em;
  cursor: pointer;
  font-size: 1.1em;
  font-weight: 500;
  align-items: center;
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.5);

}

.sidebar .sidebar--links.active {
  background-color: rgba(255, 255, 255, 0.05);
  color:  #2596be;

}

.sidebar .sidebar--links:hover {
  background-color: rgba(255, 255, 255, 0.05);
  color:  #ffffff;

}

.sidebar--links span {
  margin-right: 15px;
  margin-left: 3px;
  font-size: 1.2em;
}


.main-header {
  padding: 2em 5%;
  padding-bottom: 0;
  background-color: #f7f7f7;
  border-bottom: 1px solid rgb(224, 224, 224);
}

.profile-link {
  display: flex;
  margin-left: auto;
}

.right-track {
  display: flex;
  margin-left: auto;
  align-items: center;

}

.right-track span {
  font-size: 1.4em;
  margin-right: 0.5em;
  color: rgb(197, 197, 197);
  transition: all 0.3s ease-out;
  cursor: pointer;
}

.right-track span:hover {
  opacity: 0.94;
}

img.profile-link {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.table-container {
  width: 100%;
  height: 100%;
}

.empty-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;

}

.ant-tabs {
  flex-grow: 1 !important;
  min-height: 100% !important;
}

.ants-tabs-content {
  display: flex;
  width: 100%;
  flex: auto;
  height: 100%;
  min-width: 0;
  min-height: 0;
}

.ant-tabs-tab {
  color: gray !important;
  margin-top: 2em !important;
}

.ant-tabs-tab-btn {
  color: #b8b8b8 !important;
  font-weight: 500 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #2596be !important;
  font-weight: 500 !important;
}

.location-grid {
  display: flex;
}

.ant-select-multiple .ant-select-selection-item {
  background-color: white !important;
  height: 32px !important;
  align-items: center !important;
  border-radius: 1.5em !important;
  width: 100px !important;
  margin-left: 0.5rem !important;
}


.ant-select-multiple .ant-select-selection-item-remove {
  margin-left: auto !important;
}

.location-grid .form--unit {
  flex: 1;
}

.margin-left {
  margin-left: 2rem !important;
}

.user-hometab--content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 13vh);
  color: black;
  padding: 1em 5rem;
  overflow: hidden;
  overflow-y: scroll;
}

.user-hometab--content h1 {
  font-size:1.4em;
  font-weight: 500;
  color: #2596be;
}

.user-hometab--content p {
  margin-top: -1em;
  color: gray;
}

.next-button {
  background-color: #84AAB3;
  border: none;
  border-radius: 2em;
  padding: 0.7em;
  color: #ffffff;
  font-weight: 500;
  width: 110px;
  font-size: 1em;
  margin-left: auto;
  margin-top: 1em;
  margin-bottom: 3em;
  cursor: pointer;
}

.ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane, .ant-tabs-right > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-right: 0 !important;
}

.form--unit {
  padding: 1em 0em;
  margin: 1em 0;
  border-radius: 0.8em;
}

.form--unit h3 {
  color: gray;
  margin-top: 1.5em;
  margin-bottom: 1em;
  font-size: 0.85em;
}

.form--unit h4 {
  color: black;
  margin-top: 1.5em;
  margin-bottom: 1em;
  font-size: 0.8em;
}

.form--patient-record {
  border: 1px solid rgb(231, 231, 231) !important;
  border-radius: 1em;
  padding: 2em;
  margin-bottom: 3em;

}

.ss--divide {
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: rgb(184, 184, 184);
  margin: 0 3% !important;
}


.textarea {
  background-color: #F2F9F9 !important;
  border: none !important;
  border-radius: 1em !important;
  padding: 2em !important;
}

.textarea::placeholder {
  font-weight: 500;
  color: #a8a8a8b7;
}

.form--unit-container {
  padding: 2em 2rem;
  margin: 0.5em 0;
  border-radius: 1em;
  box-shadow: 0 5px 25px 7px rgba(145, 145, 145, 0.1);
}


.form--unit-input {
  margin-top: 1em;
}

.user-hometab--content h2 {
  font-size:1.1em;
  font-weight: 500;
  color: #84AAB3;
}

.user-hometab--content h2 span {
  color: red;
}

.empty-container img {
  object-fit: contain;
  height: 300px;
}

.empty-container h2 {
  font-size: 1.5em;
}
.empty-container p {
  font-size: 1.1em;
  line-height: 1.2em;
  width: 50%;
  max-width: 300px;
  color: gray;
}


.preloader {
  align-items: center;
  background: #ffffff;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.3s linear;
  width: 100%;
  z-index: 9999;
  -webkit-animation: cssAnimation 2.5s forwards; 
  animation: cssAnimation 2.5s forwards;
  visibility: visible;

}

.preloader .pulse {
  animation: pulse-animation 3s infinite;
  -webkit-animation: pulse-animation 3s infinite; 
}

.input-modal span {
  color: rgb(151, 151, 151);
  margin-right: 0.3em;

}

.input-modal--label {
  color: rgb(114, 114, 114);
  font-size: 0.9em;
  font-weight: 500;
  margin-bottom: 0.6em;
}

.input-modal--input {
  margin-top: 0.3em;
  margin-bottom: 1em;

  border-radius: 0.4em;
}

.ant-modal-content {
  border-bottom-left-radius: 1.5em !important;
  border-bottom-right-radius: 1.5em !important;
  border-radius: 1.5em !important;
}

.ant-modal-header {
  border-top-left-radius: 1.5em !important;
  border-top-right-radius: 1.5em !important;
  padding: 2.5em 5% !important;
  background-color: #2596be !important;
} 

.ant-modal-footer {
  padding: 2.5em 5% !important;
}

.ant-modal-title {
  color: white !important;
  font-size: 1.3em !important; 
}

.ant-modal-close {
  color: white !important;
}

.primaryButton {
  background-color: #2596be;
  border-radius: 1.5em;
  font-weight: 500;

}

.table-list--container {
  background-color: white;
  border-radius: 1em;
  width: 90%;
  margin-left: 5%;
  margin-top: 5%;
  margin-bottom: 5%;
  padding: 2em;
  padding-bottom: 2em;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.164), 0 1px 3px 1px rgb(60 64 67 / 15%);
}

.all-teams--item {
  font-size: 1.1em !important;
}

.ant-avatar {
  height: 50px !important;
  width: 50px !important;
}

.ant-card-cover img {
  border-radius: 3em !important;
  border-bottom-right-radius: 0 !important;
}

.ant-card-cover img {
  background-color: #bdbacf !important;
}

.team-name {
  color: #2596be;
}

.team-org--card .ant-list-item-meta-title {
  font-size: 1.2em !important;
}

.ant-list-item-meta-title {
  font-size: 1em !important;
}

.ant-list-item {
  padding: 15px 0 !important;
}

.member-icons {
  margin-right: 1em;
  color: gray;
}

.team-org--card {
  margin: 0 5%;
  margin-bottom: 1em;
  cursor: pointer;
  transition: all 0.3s ease-out;
  border-radius: 3em;

}

.team-org--card:hover {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.164), 0 1px 3px 1px rgb(60 64 67 / 15%);
}

.unin {
  margin: 0 3%;
  margin-right: 3%;
}

.ant-input-group.ant-input-group-compact {
  display: flex !important;
  flex-direction: row !important;
  width: 100% !important;
}

.user--in {
  flex: 1;
}

.signature-wrapper {
  position: relative;
  width: 100%;
  height: 200px;
}

canvas {
  display: block;
  background-color: #F2F9F9;
  border: 1px solid rgb(231, 228, 228);
  border-radius: 1em;
  margin-top: 1em;
  position: absolute;
  width: 100%;
  height: 100%;
}

.ant-select-selector, .user-ant--input {
  font-size: 0.9em !important;
  font-weight: 600 !important;
  color: rgb(95, 95, 95) !important;
  background-color: #F2F9F9 !important;
  border: 1px solid rgb(231, 231, 231) !important;
  border: none !important;
  border-radius: 1em !important;
  height: 50px !important;
  line-height: 50px !important;
  align-items: center !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  font-weight: 600 !important;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  font-weight: 600 !important;
}

.ant-select-selection-item {
  font-weight: 500 !important;
}

.ant-select-selection-placeholder {
  color: #979797c5 !important;
  font-weight: 500;
}

.ant-picker-input input::placeholder {
  color: #979797c5 !important;
  font-weight: 500 !important;
  font-size: 0.9em !important;
}

.ant-select-selection-item {
  padding: 0 0.5rem !important;
}

.inputgroup {
  flex-grow: 1;
}



.inputgroup .helper {
  color: #979797c5;
  font-weight: 500;
  font-size: 0.85em;
  margin: 0.3em 1%;
  cursor: pointer;
}


.ant-tooltip-inner {
  font-size: 0.8em !important;
  border-radius: 1em !important;
  width: 200px;
  padding: 1.2em 2% !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
}


.member .ant-select-selection-item {
  padding: 0 0.5% !important;

}

.empty-container h2 {
  font-size: 1.3em;
  font-weight: 600;
  color: #828594;
}

.empty-container p {
  font-size: 1.05em;
}

.toolAvatar {
  font-size: 1.4em;
  color: #ffffff;
  margin-right: 0.3em;
  background-color: #2596be;
  border-radius: 50%;
  margin-top: 1em;
  padding:0.3em 0.5em;
}

.orgName-item {
  margin-top: -0.3em;
}

.orgName-item-mid {
  flex-grow: 1;
  margin-left: 15%;
  width: 200px;
  text-align: left;
  color: #191B1D;
}

.orgName--supply {
  font-weight: 300;
  font-size: 0.9em;
  color: #969696;
  margin-top: 0.2em;
}

@keyframes pulse-animation {
  0% {
    transform: scale(1, 1);
    transform:rotate(0deg);
  }

  50% {
    transform:rotate(360deg) scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

@-webkit-keyframes pulse-animation {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5) rotate(360deg);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes cssAnimation {
  0%   {opacity: 1;}
  90%  {opacity: 1;}
  100% {opacity: 0; visibility: hidden;}
}
@-webkit-keyframes cssAnimation {
  0%   {opacity: 1;}
  90%  {opacity: 1;}
  100% {opacity: 0; visibility: hidden;}
}


@media (max-width: 400px) {
  .grid-box {
    display: block;
  }
}

@media only screen and (max-width: 900px) {
  .about-svg {
    display: none;
  }
  .grid-item {
    width: 100%;
  }
  .appbar--links {
    display: none;
  }
  .landing-first {
    flex-direction: column !important;
  }

  .header-text {
    width: 100vw;
    margin-right: 0;
    margin: 0 10%;
  }

  .header-text {
    font-size: 0.85em;
  }

  .grid-box {
    width: 80vw;
  }
  
  .footerlinks {
    display: none;
  }

}

@media only screen and (max-width: 500px) {

  .pulse-img {
    display: none;
  }

}